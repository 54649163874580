import Model from './model.js';

class ClientCountries extends Model {
  id = 0;
  regions = [];
  countries = [];

  mapForRequest() {
    return {
      regions: this.regions,
      countries: this.countries,
    };
  }
}

export default ClientCountries;
