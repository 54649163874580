<template>
  <k-field-group language-prefix="client.fields">
    <v-subheader class="pa-0">{{ $t("client.countries") }}</v-subheader>
    <v-card>
      <v-card-text>
        <v-row>
          <CountryTreeAutocomplete v-model="countriesAndRegions" field="countriesAndRegions" class="col-3"/>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-end" v-if="can('client.country.all')">
        <v-btn @click="handleSave" color="primary" :loading="isLoading">{{ $t("actions.save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </k-field-group>
</template>

<script>
import Vue from "vue";
import CountryTreeAutocomplete from "@/components/autocompletes/CountryTreeAutocomplete.vue";
import KFieldGroup from "@/components/crud/fields/KFieldGroup.vue";
import EventBus from "@/eventBus.js";
import ClientCountries from "@/application/models/clientCountries.js";
import { show, update } from "@/api/endpoints/clientCountries.js";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "ClientCountries",
  components: {
    KFieldGroup,
    CountryTreeAutocomplete,
  },
  watch: {
    countriesAndRegions() {
      this.values.regions = this.countriesAndRegions.regions;
      this.values.countries = this.countriesAndRegions.countries;
    },
    "values.countries": {
      handler() {
        this.countriesAndRegions.countries = this.values.countries;
      },
      immediate: true,
    },
    "values.regions": {
      handler() {
        this.countriesAndRegions.regions = this.values.regions;
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("profile", ["can"]),
  },
  data: () => ({
    countriesAndRegions: {
      regions: [],
      countries: [],
    },
    values: new ClientCountries(),
    isLoading: false
  }),
  async created() {
    const response = await show(this.$route.params.clientId);
    this.values = new ClientCountries().mapResponse(response.data.data);
    this.values.id = this.$route.params.clientId;
  },
  methods: {
    async handleSave() {
      try {
        this.isLoading = true;
        await update(this.values);
        EventBus.$emit("snackbar", {
          color: "success",
          text: this.$t("global.saveSuccess"),
        });
      } catch (error) {
        EventBus.$emit("snackbar", {
          color: "error",
          text: this.$t("errors.save"),
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
});
</script>
