<template>
  <v-autocomplete
      v-model="selected"
      v-bind="fieldProps"
      :items="items"
      :label="translatedLabel"
      :rules="computedRules"
      multiple
      v-on="$listeners"
  >
    <template #selection="{item}">
      <v-chip v-if="item.countryId">
        {{ item.text }}
      </v-chip>
      <v-chip v-else>
        Alles van: {{ item.text }}
      </v-chip>
    </template>
    <template #item="{item}">
      <template v-if="!item.countryId">
        <v-list-item @click="toggleRegion(item.value)">
          <v-icon
              v-if="isActive(item.value)"
              class="mr-2"
          >
            $checkboxOn
          </v-icon>
          <v-icon
              v-else-if="hasActiveChildren(item.value)"
              class="mr-2"
          >
            $checkboxIndeterminate
          </v-icon>
          <v-icon
              v-else
              class="mr-2"
          >
            $checkboxOff
          </v-icon>
          {{ item.text }}
        </v-list-item>
      </template>
      <template v-else>
        <v-list-item @click="toggleCountry(item.value)">
          <v-icon
              v-if="isActive(item.value) || hasActiveParent(item.value)"
              class="mr-2 ml-6"
          >
            $checkboxOn
          </v-icon>
          <v-icon
              v-else
              class="mr-2 ml-6"
          >
            $checkboxOff
          </v-icon>
          {{ item.text }}
        </v-list-item>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import Field from '@/components/crud/fields/FieldMixin.vue';
import { index } from '@/api/endpoints/country.js';

export default {
  name: 'CountryTreeAutocomplete',
  mixins: [Field],
  props: {
    value: {
      type: Object,
      default: () => ({
        regions: [],
        countries: [],
      }),
    },
  },
  data() {
    return {
      items: [],
      selected: [],
    };
  },
  watch: {
    value: {
      handler() {
        this.convertArraysInSelected(this.value);
      },
      deep: true,
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    toggleCountry(valueString) {
      const splitter = valueString.split('.');
      const regionId = parseInt(splitter[0]);
      if (this.hasActiveParent(valueString)) {
        this.toggleValue(`${regionId}.all`);
        this.addAllCountries(regionId);
      }
      this.toggleValue(valueString);
      this.selectRegionsWhenAllCountriesAreSelected(regionId);

      this.$emit('input', this.convertSelectedInTwoArrays(this.selected));
    },
    toggleRegion(valueString) {
      const splitter = valueString.split('.');
      const regionId = parseInt(splitter[0]);
      if (this.selected.indexOf(valueString) === -1) {
        // because a region is selected as a whole, individual countries can be removed
        this.removeAllCountriesByRegion(regionId)
      }
      this.toggleValue(valueString);

      this.$emit('input', this.convertSelectedInTwoArrays(this.selected));
    },
    toggleValue(value) {
      if (this.selected.indexOf(value) === -1) {
        this.selected.push(value);
      } else {
        this.selected.splice(this.selected.indexOf(value), 1);
      }
    },
    selectRegionsWhenAllCountriesAreSelected(regionId) {
      const totalCountriesInRegion = this.items.filter(item => item.regionId === regionId && item.countryId).length;
      const selectedCountriesInRegion = this.selected.filter(item => item.indexOf(`${regionId}.`) === 0).length;
      if (totalCountriesInRegion === selectedCountriesInRegion) {
        this.removeAllCountriesByRegion(regionId)
        this.toggleValue(`${regionId}.all`);
      }

    },
    removeAllCountriesByRegion(regionId) {
      this.selected = this.selected.filter(value => {
        const splitter = value.split('.');
        return parseInt(splitter[0]) !== regionId;
      });
    },
    addAllCountries(regionId) {
      this.items.filter(item => item.regionId === regionId && item.countryId)
          .forEach(item => {
        this.selected.push(`${item.regionId}.${item.countryId}`);
      });
    },
    async getItems() {
      this.items = [];
      const response = await index();
      response.data.data.forEach(region => {
        this.items.push({
          text: region.regionName,
          regionId: region.regionId,
          value: `${region.regionId}.all`,
        });
        region.countries.forEach(country => {
          this.items.push({
            text: country.countryName,
            countryId: country.countryId,
            regionId: region.regionId,
            value: `${region.regionId}.${country.countryId}`,
          });
        });
      });
      this.convertArraysInSelected(this.value);
    },
    isActive(valueString) {
      return this.selected.find(value => value === valueString);
    },
    hasActiveChildren(valueString) {
      const splitter = valueString.split('.');
      const regionId = parseInt(splitter[0]);

      return this.selected.find((childString) => {
        const childSplitter = childString.split('.');
        const childregionId = parseInt(childSplitter[0]);

        return childregionId === regionId;
      });
    },
    hasActiveParent(valueString) {
      const splitter = valueString.split('.');
      const regionId = splitter[0];
      return this.selected.find(parentString => parentString === `${regionId}.all`);
    },
    getregionBycountry(country) {
      return this.items.find(item => item.countryId === parseInt(country)).regionId;
    },
    convertSelectedInTwoArrays(array) {
      const regions = [];
      const countries = [];

      array.forEach(valueString => {
        const splitter = valueString.split('.');
        const regionId = splitter[0];
        const countryId = splitter[1];

        if (countryId === 'all') {
          regions.push(regionId);
          return;
        }
        countries.push(countryId);
      });
      return {
        regions,
        countries,
      };
    },
    convertArraysInSelected(values) {
      if (this.items.length === 0) {
        return;
      }
      const {
        regions,
        countries,
      } = values;
      this.selected = [];

      if (regions) {
        regions.forEach(region => {
          this.selected.push(`${region}.all`);
        });
      }
      if (countries) {
        countries.forEach(country => {
          const region = this.getregionBycountry(country);
          this.selected.push(`${region}.${country}`);
        });
      }
    },
  },
};
</script>
